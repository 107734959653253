/** @format */

let singleton;
let cartData;

class CartUtils {
  constructor(fullCartObj={}, setCartData=()=>{}) {
    if (singleton) {
      return singleton;
    } else {
      cartData = fullCartObj.lines || [];
      this.setCartData = setCartData;
      this.subtotal = 0;
      this.shipping = 0;
      this.grandTotal = 0;
      this.message = {};
      this.zip = 0;
      this.freeShipping = false;
      this.bulkItem = false;
      this.serviceCode = 95;
      this.membership = false;
      // Binding methods so that 'this' always refers to the class
      this.addItemToCart = this.addItemToCart.bind(this);
      this.deleteItem = this.deleteItem.bind(this);
      this.removeOneItem = this.removeOneItem.bind(this);
      this.updateItems = this.updateItems.bind(this);
      setCartData(cartData);
      // this.updateTotals();
      singleton = this;
    }
  }

  setCart(fullCartObj) {
    cartData = fullCartObj.lines || [];
    this.setCartData(cartData);
  }

  //#region Accessors
  get cart() {
    return cartData;
  }

  get formattedTotals() {
    return {
      shipping: this.shipping.toFixed(2),
      subtotal: this.subtotal.toFixed(2),
      total: this.grandTotal.toFixed(2),
    };
  }

  get itemCount() {
    return cartData.reduce((acc, item) => (acc += item.qty), 0);
  }

  get items() {
    return cartData;
  }

  //#endregion

  //#region Functions
  static getSingleton() {
    return singleton;
  }

  clear() {
    this.message = {};
    this.setCartData([]);
  }

  addItemToCart({type = 'item', id, quantity = 1, options = {}, sides = []}) {
    const body = {
      type: type,
      id: id,
      qty: quantity,
      options: options,
      sides: sides,
    };
    fetch('/api/cart', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(async res => {
        if (!res.ok) {
          // get error message from body or default to response status
          console.log(res);
          return Promise.reject(await res.text());
        } else {
          return res.json();
        }
      })
      .then(data => {
        this.message = {msg: 'Item added to cart.', type: 'success'};
        this.updateItems(data.lines);

        if (process.env.NODE_ENV === 'production') {
          try{
            const [lastItem] = data.lines.slice(-1);
            window.gtag("event", "add_to_cart", {
              value: Number(lastItem.price) * lastItem.qty,
              currency: "USD",
              items:[{
                item_id: lastItem.itmeId,
                item_name:lastItem.name,
                item_category:lastItem.options.material,
                price:Number(lastItem.price),
                quantity:lastItem.qty
              }]
            });
          } catch(e) {}
        }
      })
      .catch(err => {
        this.message = {msg: err, type: 'error'};
        this.getCart();
      });
  }

  getCart() {
    fetch('/api/cart', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(resp => resp.json())
      .then(
        data => {
          // console.log(data);
          this.updateItems(data.lines);
        },
        error => {
          console.log('error: ', error);
        }
      );
  }

  deleteItem(lineId) {
    fetch(`/api/cart/${lineId}`, {
      method: 'DELETE',
    })
      .then(resp => resp.json())
      .then(data => {
        if (data.deleted) {
          this.message = {msg: 'Item removed.', type: 'success'};
          const deletedItem = cartData.filter(i => i.id === data.id)[0];
          this.updateItems(cartData.filter(i => i.id !== data.id));
          if (process.env.NODE_ENV === 'production') {
            try{
              window.gtag("event", "remove_from_cart", {
                value: Number(deletedItem.price) * deletedItem.qty,
                currency: "USD",
                items:[{
                  item_id: deletedItem.itmeId,
                }]
              });
            } catch(e) {}
          }
        }
      });
  }

  removeOneItem(itemId) {
    let targetItem = cartData.find(i => i.itemId === itemId);

    if (targetItem) {
      const body = {
        type: 'item',
        qty: targetItem.qty - 1,
      };
      fetch(`/api/cart/${targetItem.id}`, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.json())
        .then(data => {
          this.message = {};
          this.updateItems(data.lines);
        });
    }
  }

  updateItems(newItems) {
    cartData = newItems;
    this.setCartData(newItems);
    // this.updateTotals();
  }

  setStandardShipping() {
    this.localPickup = false;
    this.zip = this.oldZip || '';
    this.serviceCode = 95;
  }

  setOvernightShipping() {
    this.localPickup = false;
    this.zip = this.oldZip || '';
    this.serviceCode = 1;
  }

  setLocalPickup() {
    this.localPickup = true;
    this.oldZip = this.zip;
    this.zip = '48315';
    this.serviceCode = 0;
  }


  addMsg(data) {
    this.message = data;
    this.getCart();
  }

  clearMsg() {
    this.message = {};
  }

  getShipping(zip) {
    if(this.localPickup === true) {
      return;
    }
    if(zip) {
      this.zip = zip;
    }
    return fetch(`/api/shipping/rates?postalCode=${this.zip.replace(' ','')}&getCart=true`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(resp => resp.json())
      .then(
        data => {
          if (Array.isArray(data)) {
            let groundAvailable = false;
            let groundRate;
            data.forEach(s => {
              if (s.service === 'FEDEX_INTERNATIONAL_ECONOMY') {
                this.shipping = s.rate - 10;
                this.service = s;
                this.serviceCode = 200;
              }
              if (s.service === 'FEDEX_GROUND') {
                groundRate = s.rate - 10;
                this.groundService = s;
                groundAvailable = true;
              }
            });

            if (groundAvailable === true) {
              this.shipping = groundRate;
              this.serviceCode = 92;
            }
          } else {
            this.msg = data.message;
          }
        },
        error => {
          console.log('error: ', error);
        }
      );
  }

  getTotals(data) {
    let subtotal = data.reduce(
      (acc, item) =>
        (acc += Number(item.price) * Number(item.qty)),
      0
    );

    this.freeShipping = true;
    this.bulkItem = false;
    let excludeAmount = 0;
    let errors = false;
    let errorMsg = "";
    data.forEach(item=>{
      if(this.freeShipping === true && item.data && item.data.freeGround !== true) {
        this.freeShipping = false;
        
      }
      if(item.error) {
        errors = true;
        errorMsg += item.error + '\n';
      }
      if(item.options && item.options.material === 'parts') {
        excludeAmount += (Number(item.price) * Number(item.qty));
      }
      if(item.data && item.data.freeGround === true) {
        this.bulkItem = true;
        excludeAmount += (Number(item.price) * Number(item.qty));
      }
    });

    let totalRolls = data.reduce(
      (acc, item) => (acc += Number(item.qty)),
      0
    );
    // TODO replace with calculation
    let shipping = data.length > 0 ? 5 : 0;
    if (this.international === true) {
      shipping = this.shipping || 20;
    }

    if(this.localPickup === true) {
      shipping = 0;
    }

    if(this.serviceCode === 1) {
      shipping = 10;
    }

    if(this.freeShipping===true && this.international !== true) {
      shipping = 0;
      this.serviceCode = this.localPickup?0:95;
    }

    let discountAmount = 0;

    if(this.serviceCode !== 1 && this.membership === true) {
      shipping = 0;
    }

    if(this.membership === true) {
      discountAmount = (subtotal-excludeAmount) * .1;
    }

    if (data.couponData) {
      const {discount, minAmount} = data.couponData;
      if (minAmount.subtotal <= subtotal) {
        if (discount.amount) {

          discountAmount = discount.amount / 100;
        } else if (discount.percent) {

          discountAmount = (subtotal * (discount.percent / 100));
        } else if (discount.tier) {

          discount.tier.some(t => {
            if (totalRolls >= t.min && totalRolls <= t.max) {
              discountAmount = (subtotal * (t.discount / 100));
              return true;
            } else {
              return false;
            }
          });
        }
      } else {
        //add notification
      }
    }

    let grandTotal = subtotal + shipping - discountAmount;
    let tax = data.taxExempt === true?0:grandTotal * getTaxRate(this.zip);
    grandTotal += tax;

    return {
      grandTotal: grandTotal.toFixed(2),
      shipping: shipping.toFixed(2),
      subtotal: subtotal.toFixed(2),
      tax: tax.toFixed(2),
      discount: discountAmount.toFixed(2),
      service: this.service,
      errors,
      errorMsg
    };
  }
}

export default CartUtils;

function getTaxRate(zipString) {
  /* Ensure param is a string to prevent unpredictable parsing results */
  if (typeof zipString !== 'string') {
    return 0;
  }

  /* Ensure we have exactly 5 characters to parse */
  if (zipString.length !== 5) {
    return 0;
  }

  /* Ensure we don't parse strings starting with 0 as octal values */
  const zipcode = parseInt(zipString, 10);

  let tax;
  if (zipcode >= 48000 && zipcode <= 49999) {
    tax = 0.06;
  } else {
    tax = 0;
  }

  return tax;
}
