/** @format */

import React, {useEffect, useRef} from 'react';
import clsx from 'clsx';
import {detect} from 'detect-browser';
// Material UI Component Imports
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// Feather icons
import {ArrowLeft, Trash2} from 'react-feather';
// Material UI Icons
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
// Custom Scrollbars import
import {Scrollbars} from 'react-custom-scrollbars';
// Swipe to delete
// import SwipeToDelete from 'react-swipe-to-delete-ios';
// Styling import
import CartStyles from './styles';
// Custom imports
import SunNavButton from '@sunsh1n3/sun-nav-button';

const browser = detect();

function VwToPx(vh) {
  let oneVw = (window.innerWidth / 100.0).toFixed(2);
  return oneVw * vh;
}

export default function CartItems(props) {
  const pgStyles = CartStyles();
  const {
    hasCheckoutButton = false,
    title = 'Shopping Cart',
    close = () => {},
    cartData,
    cartUtils,
  } = props;
  const totals = cartUtils ? cartUtils.getTotals(cartData) : 0;
  const browserPadding = ['safari', 'crios', 'ios', 'chrome'];

  return (
    <div
      className={clsx(
        pgStyles.container,
        browserPadding.includes(browser.name) ? 'safari-pad' : ''
      )}
    >
      <IconButton className={pgStyles.btnClose} onClick={close}>
        <ArrowLeft color="#3d3d3d" />
      </IconButton>
      <div className={pgStyles.titles}>
          <Typography variant="h2" align="left">
            {title}
          </Typography>
          <Typography variant="h2" align="left" className="title-qty">
            ({cartData.length})
          </Typography>
        </div>
      <div className={pgStyles.orderInfo}>
          {cartData.map(item => {
            return (
                <Card className={pgStyles.card} variant="outlined">
                  <CardMedia
                    component="img"
                    alt={item.name}
                    image={
                      item.images +
                      `?w=` +
                      Math.round(48 * window.window.devicePixelRatio)
                    }
                    title={item.name}
                    className={pgStyles.cardMedia}
                    onError={e => (e.target.src = '/images/PrintBed.svg')}
                  />
                  <CardContent
                    className={pgStyles.cardContent}
                    style={{
                      padding: 0,
                    }}
                  >
                    <div className="name text">
                      <Typography variant="h2">
                        {item.options.colorName}
                      </Typography>
                      <Typography variant="h2">
                        {item.options.material} {item.options.weight}{' '}
                        {item.options.diameter}
                      </Typography>
                      {item.options.material === 'MINI' || item.options.material === 'BULK' && !item.error && 
                        <Typography variant="subtitle1">
                          {item.options.color}
                        </Typography>}
                      {item.error && <Typography variant="h2"style={{color:'red'}}>{item.error}</Typography>}
                    </div>
                    <Typography className="text" variant="h3">
                      $
                      {(
                        Number.parseFloat(item.price) *
                        Number.parseFloat(item.qty)
                      ).toFixed(2)}
                    </Typography>
                    <IconButton
                      disableRipple
                      className="text"
                      size="small"
                      onClick={e =>
                        cartUtils.deleteItem(item.id)
                      }
                    >
                      <Trash2 />
                    </IconButton>
                    <CardActions className={pgStyles.cardActions}>
                      <div className="btn-wrapper">
                        <IconButton
                          disableRipple
                          className="qty-btn add"
                          onClick={e =>
                            cartUtils.addItemToCart({id: item.itemId})
                          }
                        >
                          <AddCircleIcon />
                        </IconButton>
                        <Typography id="qty-txt">{item.qty}</Typography>
                        <IconButton
                          disableRipple
                          className="qty-btn remove"
                          disabled={item.qty === 0}
                          onClick={e => cartUtils.removeOneItem(item.itemId)}
                        >
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      </div>
                    </CardActions>
                  </CardContent>
                </Card>
            );
          })}
      </div>
      <div className={pgStyles.totalsContainer}>
        <div className={pgStyles.totals}>
          <Typography variant="h2">Subtotal: </Typography>
          <Typography variant="h2"> ${totals.subtotal}</Typography>
        </div>
        <Divider />
        <div className={pgStyles.totals}>
          <Typography variant="h2">Shipping: </Typography>
          <Typography variant="h2">
            {totals.shipping > 0 ? ` $${totals.shipping}` : ' Free Shipping'}
          </Typography>
        </div>
        <Divider />
        {totals.tax > 0 && (
          <>
            <div className={pgStyles.totals}>
              <Typography variant="h2">Tax: </Typography>
              <Typography variant="h2">{totals.tax}</Typography>
            </div>
            <Divider />
          </>
        )}
        <div className={pgStyles.totals}>
          <Typography variant="h1">Total: </Typography>
          <Typography className="grand-total" variant="h1"> ${totals.grandTotal}</Typography>
        </div>
        {hasCheckoutButton ? <Divider /> : null}
      </div>
      <div style={{flexGrow: 1}}></div>
      {hasCheckoutButton ? (
        <div className={pgStyles.checkoutButtons}>
          <SunNavButton
            to="/checkout"
            className={
              pgStyles.checkoutButton + (hasCheckoutButton ? '' : ' removed')
            }
            disabled={cartData.length <= 0 || totals.errors}
            label={totals.errors?"*Fix your quantites of Out of Stock items.":"Checkout"}
          />
        </div>
      ) : null}
    </div>
  );
}
