

const div = document.createElement('div');
    // div.className = 'label';
    // // div.textContent = 'Hello, Three.js!';
    // div.style.backgroundColor = 'white'
    // div.style.width = '200px'
    // div.style.height = '200px'
    // div.style.overflow = 'hidden';
    // div.style.borderRadius = '20px';
    div.innerHTML = `<div id="uploadContainer">
        <div class="header"><img src="https://cdn.sunsh1n3.com/Images/queu3d/White.png?w=400" alt="quee3d logo" /></div>
        <div class="body">
            <ol class="list">
                <li>Find 3D print models on free websites like<br/>
                <a href="https://www.thingiverse.com/" target='_blank'>www.Thingiverse.com</a>, <a href="https://www.printables.com/" target="_blank">www.Printables.com</a>, or <a href="https://cults3d.com/" target="_blank">www.Cults3D.com</a>
                </li>
                <li>Download the file in STL, 3MF, or OBJ format.</li>
                <li><a href="#" id="uploadLink">Upload</a> your file to QUEU3D below.</li>
                <li>Customize your settings (color, size, quality, etc).</li>
                <li>Click <strong>Calculate Price</strong> and wait for your price.</li>
                <li><strong>Add to cart</strong> and check out!</li>
            </ol>
            <div id="filePondContainer">
                <input type="file" 
                    id="filepond"
                    class="filepond"
                    name="upfile"  
                    data-allow-reorder="false"
                    data-max-file-size="100MB"
                    data-max-files="1">
            </div>
        </div>
    </div>`;
    

export default div;