/** @format */

import React, {useState, useEffect, useRef} from 'react';
import {io} from 'socket.io-client';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// Custom Components

import ColorPicker from '../../components/colorpicker';
import './style.css';
import {useSnackbar} from 'notistack';
import Select from '@material-ui/core/Select';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { getColor } from './slicer/utils.js';
import Viewer  from './slicer/index.js';
import {CuraWASM} from 'cura-wasm';
import {resolveDefinition} from 'cura-wasm-definitions';
import Box from '@material-ui/core/Box';
import { overrides } from './settings.js';
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
const POD_ITEM = "6697e08fca89cda62f4c8298";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width:'100%',
    height:'calc(100vh - 80px)',
    backgroundColor:'#EFEFEF'
  },
  button: {
    marginBottom:'2rem'
  },
  paper: {
    backgroundColor:'white',
    padding:'1rem 2rem',
    borderRadius:'2rem',
    display:'flex',
    alignItems:'baseline',
    justifyContent:'space-between',
    marginBottom:'2rem'
  },
  paperSummary: {
    position:'relative',
    backgroundColor:'white',
    padding:'1rem 2rem',
    borderRadius:'2rem',
    // display:'flex',
  },
  uploadGrid: {
    position:'relative',
    display:'flex',
    marginTop: -50,
    justifyContent:'center',
    alignItems:'center',
  },
  rotateContainer: {
    position:'absolute',
    top:80,
    right:0,
    zIndex:100
  },
  uploadIconContainer: {
    position:'absolute',
    bottom: 33,
    left: 20,
    cursor:'pointer',
  },
  uploadIcon:{
    height:70,
    width:150,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    backgroundImage:'url("/images/pbicon.png")',
    backgroundSize: 'contain',
    backgroundRepeat:'no-repeat',
    '& img': {
      marginBottom:100,
      backgroundColor:'#B2DAFF44',
      border: '1px dashed #B2DAFF',
      padding: '10px 20px',
      borderRadius:10,
      width:80
    }
  },
  loading:{
    backgroundColor:'transparent',
    height:400,
    padding: '5rem',
    textAlign:'center',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    width:'50%',
  },
  uploader:{
    backgroundColor:'white',
    height:400,
    padding: '5rem',
    textAlign:'center',
    width:'50%',
    // zIndex:10000,
    borderRadius:'2rem',
    '& .filepond--hopper': {
      height:'200px !important'
    }
  },
  control: {
    padding: theme.spacing(2),
  },
  settings: {
    height:'95%',
    marginTop: '3rem',
    display:'flex',
    flexDirection:'column',
    overflowY:'scroll'
    // justifyContent:'space-between'
  },
  formControl:{
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 200,
    textAlign:'center',
  },
  textField: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 200,
    '& input':{
      textAlign:'center'
    }
  },
  slider:{
    '& .MuiSlider-markLabel': {
      color:'black',
      '&[data-index="0"]': {
        left:'10% !important'
      },
      '&[data-index="2"]': {
        left:'90% !important'
      }
    },
  },
  progress: {
    position:'absolute', 
    top: '50%', 
    left: '50%', 
    marginTop: '-2.5rem',
    marginLeft: '-1.5rem',
  },
  InputAdornment:{
    '& .MuiTypography-colorTextSecondary':{
      color:'black'
    }
  },
  accordian: {
    backgroundColor:'white',
    boxShadow:'none',
    "& .MuiAccordionSummary-root": {
      padding:0
    }
  },
  mobilePaper: {
    padding:'4rem',
    width:'100%',
    height:'100%',
    color:'black',
    fontSize:'10rem',
    textAlign:'justified',
    '& ol': {
      padding:'6rem'
    }
  },
  alertDialog: {
    color:'black'
  },
  alertList: {
    listStyleType: 'none',
    padding:'1rem',
    counterReset: 'elementcounter',
    '& li:before': {
      content: '"Step " counter(elementcounter) ": "',
      counterIncrement: 'elementcounter',
      fontWeight: 'bold'
    },
    '& li': {
      paddingBottom:'2rem'
    }
  },
  alertTitle: {
    backgroundColor:'black',
    textAlign:'center'
  },
  helpButton: {
    position:'absolute',
    top:0,
    right:0,
    zIndex:10
  }
}));

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

function ValueLabelQualityComponent(props) {
  const { children, open, value } = props;
  const item = marks.find(obj => obj.value === value);
  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={item.title}>
      {children}
    </Tooltip>
  );
}

const marks = [
  {
    value: 0,
    label: 'High Quality',
    title: 'Fine - 0.12mm'
  },
  {
    value: 50,
    label: 'Standard',
    title: 'Standard - 0.16mm',
  },
  {
    value: 100,
    label: 'High Speed',
    title: 'Fast - 0.20mm',
  },
];

let pInfill = 20;

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: 'text.secondary' }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

let throttle,socket, nextStep;

const PodPage = props => {
  const {cartUtils, isMobile} = props;
  const viewerRef = useRef(null);
  const priceRef = useRef(null);
  const [materials, setMaterials] = useState([]);
  const [material,setMaterial] = useState("PLA");
  const [sliceMap,setSliceMap] = useState({});
  const [rotation,setRotation] = useState(false);
  const [infill,setInfill] = useState(20);
  const [quality,setQuality] = useState(50);
  const [color,setColor] = useState('black');
  const [uploading,setUploading] = useState(false);
  const [qty,setQty] = useState(1);
  const [file,setFile] = useState();
  const [fileType,setFileType] = useState("");
  const [modelData,setModelData] = useState({});
  const [isVase,setIsVase] = useState(false);
  const [preview,setPreview] = useState();
  const [colorMap,setColorMap] = useState({});
  const [item,setItem] = useState({price:0})
  const [scale,setScale] = useState(100);
  const [progress,setProgress] = useState(0);
  const [init,setInit] = useState(false);
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  
  const defaultTags = {
    color: 'Black',
    material: 'PLA',
    weight: '1kg',
    diameter: '1.75mm',
    colorName: 'Black',
  };


  useEffect(() => {
    getPODItem();
    fetch(`/api/items/tags?q=tags.material=PLA,PLA Ltd,FORGE,PETG,ABS%26tags.weight=1kg`)
      .then(resp => resp.json())
      .then(data => {
        let tmp = new Set();
        let tmpCM = {}
        data.forEach(item=>{
          tmp.add(item.tags.material);
          if(!tmpCM[item.tags.material]) {
            tmpCM[item.tags.material] = new Set();
          }
          tmpCM[item.tags.material].add(item.tags.color.toTitleCase());
        });

        setMaterials([...tmp]);
        Object.keys(tmpCM).forEach(key=>{
          tmpCM[key] = Array.from(tmpCM[key]);
        });
        setColorMap(tmpCM);
        setColor(getRandomItem(tmpCM["PLA"]));
      });
      socketInit();
      return () => {
        reset();
        if(socket) {
          socket.destroy();
        }
      };
    // eslint-disable-next-line
  }, []);
  const handleChange = (event) => {
    setMaterial(event.target.value);
  };

  const socketInit = async (fileId) => {
    const wsUrl = window.location.origin
    .replace('https', 'wss')
    .replace('http', 'ws');
  
    const opts = {
        reconnectionDelayMax: 10000,
        path: '/api/status/sockets/',
        reconnection: false,
        transports:
        process.env.NODE_ENV !== 'production'
            ? ['polling']
            : ['polling', 'websocket'], //cannot proxy more that one ws using the hpm so resort to polling in dev.
    };
  
    socket = io(wsUrl, opts);
  }

  const getKeys = () => {
    let q = (marks.find(obj => obj.value === quality)).title;
    const match = q.match(/[\d.]+(?=mm)/);
    const number = parseFloat(match[0]);
    const dataKey = `${infill}-${scale}-${rotation}`;
    return {dataKey, layerHeight: number};
  }

  const slice =  async (stl) => {
    const {dataKey,layerHeight} = getKeys();
    const slicer = new CuraWASM({
      // command: `slice -j definitions/printer.def.json -o Model.gcode ${overrides} -l Model.stl`,
      definition: resolveDefinition('voron2_350'),
      overrides: overrides(layerHeight,infill,'local'),
      transfer: true,
      verbose: false
    });

    slicer.on('progress', setProgress);
    try {
      const {metadata} = await slicer.slice(stl, 'stl');
      setSliceMap(prev=>{
        let tmp = {...prev};
        tmp[dataKey] = metadata
        return tmp;
      });
      slicer.destroy();
      setProgress(0);
    } catch(e) {
      console.error(e);
      setProgress(0);
      slicer.destroy();
    }
  }

  const getPODItem = () => {
    fetch(`/api/item/6697e08fca89cda62f4c8298`).then(resp => resp.json())
    .then(data => {
      setItem(data);
    });
  }

  const getDensity = () => {
    let density;
    switch(material) {
      case 'PETG': 
        density = 1.27;
      break;
      case 'ABS': 
        density = 1.07;
      break
      default:
        density = 1.24;
        break;
    }
      return density;
  }

  useEffect(()=>{
    if(colorMap[material]) {
      setColor(getRandomItem(colorMap[material]));
    }
  },[material]);

  const colorChange = (e) => {
    setColor(e.target.value);
  }

  const previewLoaded = (preview) => {
    // let previewUrl = `/api/file/${preview.id}/preview`
    setPreview(preview);
  }

  const getVolume = () => {
    // console.log("getVolume");
    const {modelVolume=0,surfaceAreaVolume=0} = modelData || {};
    const scaledVolume = modelVolume * scale/100 / 1000;
    const scaledSurfaceAreaVolume = surfaceAreaVolume * scale/100;
    let V_effective = ((scaledVolume - scaledSurfaceAreaVolume) * (infill/100)) + (scaledSurfaceAreaVolume);

    const {dataKey} = getKeys();
    if(sliceMap[dataKey]) {
      const {filamentUsage} = sliceMap[dataKey];
      const diameter = 1.75; // Diameter in mm
      const density = getDensity();  // Density of PLA in g/cm³
  
      // // Calculate the cross-sectional area in mm²
      // const radius = diameter / 2;
      // const crossSectionalArea = Math.PI * Math.pow(radius, 2); // mm²
  
      // Calculate the volume in mm³
      const volumeInMm3 = filamentUsage; // mm³
  
      // Convert volume to cm³ (1 cm³ = 1000 mm³)
      const volumeInCm3 = volumeInMm3 / 1000; // cm³
  
      // Calculate mass in grams
      const massInGrams = volumeInCm3 * density; // g
  
      return massInGrams.toFixed(2);
    } 

    if(isVase) {
      V_effective = scaledSurfaceAreaVolume;
    }
    // Calculate the mass in grams
    const mass = V_effective * getDensity();
    return mass.toFixed(2);
  }

  const reset = () => {
    setFile(null);
    setPreview("");
    setInfill(20);
    setScale(100);
    setRotation(false);
    setSliceMap({});
    setModelData({});
    setUploading(false);
    priceRef.current = {};
  }

  const setVaseMode = (e) => {
    const checked = e.target.checked;
    if(checked) {
      pInfill = infill;
      setInfill(0);
    } else {
      setInfill(pInfill);
    }
    setIsVase(checked);
  }

  const handleAddFile = (error, file) => {
    if (error) {
      console.log('Error adding file:', error, file.fileType);
      return;
    }
    setSliceMap({});
    setRotation(false);
    setFileType(file.fileExtension.toLowerCase());
    setFile(file.file);
    setInit(true);
  };

  const addToCart = () => {
    const {dataKey} = getKeys();

    const pricing =  getPrice();
    priceRef.current = {
      type:'pod', 
      id:POD_ITEM, 
      quantity:qty, 
      options:{ 
        material:material,
        color:color,
        infill:infill,
        quality:(marks.find(obj => obj.value === quality)).title, 
        price:pricing.itemPrice.toFixed(2)
      }
    }
    if (sliceMap[dataKey]?.fileId) {
      addToCartStep2(sliceMap[dataKey]?.fileId);
    } else {
      setUploading(true);     

      if(scale !== 100) {
        // console.log(file)
        const blob = viewerRef.current.getFile();
        const formData = new FormData();
        var fileOfBlob = new File([blob], file.name);
        formData.append('upfile', fileOfBlob);
        fetch('/api/file/?ttl=' + 3600, {
          method: 'POST',
          body: formData,
          headers: {
            Accept: 'application/json',
          },
        }).then(function (res) {
          return res.json();
        }).then(resp=>{
          addToCartStep2(resp.id);
        });
      } else {
        nextStep = addToCartStep2;
        viewerRef.current.uploadFile(); // Trigger the file upload manually
      }
    }
  }

  const addToCartStep2 = async (fileId) => {
    if(!fileId) {
      enqueueSnackbar('There was a problem uploading your file.', {variant:'error'});
    }
    await uploadPreview(fileId);
    const previewUrl = `https://www.printbed.com/api/file/${fileId}/preview`;
    const data = priceRef.current;
    data.options.fileId = fileId;
    data.options.preview = previewUrl;
    await cartUtils.addItemToCart(data);
    
    reset();
  }

  const uploadPreview = async (fileId) =>{
    let tmp = await viewerRef.current.getPreview();

    let data = {
        preview: {
        type: 'img',
        value: tmp
        }
    };

    return fetch(`/api/file/${fileId}`, {
        method: 'put',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(res => res.json()).then(data => {
        return data;
    }).catch(e => {
    });
  }

  const getPrice = () => {
    const {dataKey} = getKeys();
    const {modelVolume=0} = modelData || {};
    let price = item.price;
    if(quality===0) {
      price += 5;
    }
    if(quality===100) {
      price -= 5;
    }
    const data = {itemPrice: 0,subTotal:0, cost:price}
    // console.log(data,sliceMap[dataKey]);
    if(!sliceMap[dataKey] || !file) {
      return data;
    }
    if(modelVolume){
      data.itemPrice = Math.round(getVolume(modelData)*(price/100)*100)/100;
      data.subTotal = data.itemPrice * qty;
    } 
    return data;
  }

  const calculatePrice = () => {
    const {dataKey,layerHeight} = getKeys();
    setProgress(1);
    if (!sliceMap[dataKey] && viewerRef.current && file && modelData.modelSize?.depth < 20) {
      slice(viewerRef.current.getFile('buffer'),dataKey,layerHeight,infill);
    } else {
      if(scale !== 100) {
        // console.log(file)
        const blob = viewerRef.current.getFile();
        const formData = new FormData();
        var fileOfBlob = new File([blob], file.name);
        formData.append('upfile', fileOfBlob);
        fetch('/api/file/?ttl=' + 3600, {
          method: 'POST',
          body: formData,
          headers: {
            Accept: 'application/json',
          },
        }).then(function (res) {
          return res.json();
        }).then(resp=>{
          sendSliceJob(resp.id);
        });
      } else {
        nextStep = sendSliceJob;
        viewerRef.current.uploadFile(); // Trigger the file upload manually
      }
    }
  }

  const updateProject = (data)=>{
    if(data.percent>0) {
      setProgress(Math.floor(data.percent));
    }
    if(data.status === "complete") {
      const {dataKey} = getKeys();
      setSliceMap(prev=>{
        let tmp = {...prev};
        if(!tmp[dataKey]) {
          tmp[dataKey] = {fileId:data.projectId};
        }
        tmp[dataKey].filamentUsage = data.materialVolume;
        tmp[dataKey].printTime = data.printTime;
        return tmp;
      });
      setProgress(0);
    }
  }

  
  const sendSliceJob = (fileId) => {
    const {dataKey,layerHeight} = getKeys();

    socket.on('projectUpdate', updateProject);
    socket.emit('project', fileId);
    const body = {
      "fileId":fileId,
      "projectId":fileId,
      "type":"slice",
      "settings":{
        "overrides":overrides(layerHeight,infill,'api'),
        "printer":{"id":"64aeba79283998208ca3f6bd","definitionName":"voron2_350.def.json"}
      }
    };
    
    fetch('/api/jobs', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(function (res) {
      return res.json();
    }).then(jobData => {
      // console.log(jobData)
      // setJobData({ type: 'gcode', percent: 0, status: 'queued' });
    });
  }

  const handleUploadFile = (fileId) => {
    const {dataKey} = getKeys();
    setSliceMap(prev=>{
      let tmp = {...prev};
      if(!tmp[dataKey] ) {
        tmp[dataKey] = {};
      }
      tmp.fileId = fileId;
      return tmp;
    });
    nextStep(fileId);
  }

  useEffect(()=>{
    if(modelData.oversized === true) {
      enqueueSnackbar('This model is too loarge for us to print.', {variant:'error'});
    }
  },[modelData])

  const handleRotate = dir => () => {
    viewerRef.current.rotateModel(dir);
    setRotation(true);
  }

  return (
    <>
    {isMobile || navigator.userAgent === 'ReactSnap'?<>
      <Grid container className={classes.root} spacing={0}>
        <Grid item xs={12} >
          <Paper className={classes.mobilePaper}>
              <img src="https://cdn.sunsh1n3.com/Images/queu3d/QUEU3D_Beta_Logo.png" width="250" height="300" style={{width:'100%', height:'auto'}} alt="" />
              <br/>
              <ol className={classes.alertList}>
              <li>Visit our print on demand service on a larger screen.</li>
              <li>Find 3D print models on free websites like<br/>
              <a href="https://www.thingiverse.com/" target='_blank'>www.Thingiverse.com</a>, <a href="https://www.printables.com/" target="_blank">www.Printables.com</a>, or <a href="https://cults3d.com/" target="_blank">www.Cults3D.com</a>
              </li>
              <li>Download the file in STL, 3MF, or OBJ format.</li>
              <li>Upload your file to QUEU3D.</li>
              <li>Customize your settings (color, size, quality, etc).</li>
              <li>Wait for your price to calculate.</li>
              <li>Add to cart and check out!</li>
            </ol>
            <br/>
          </Paper>
        </Grid>
      </Grid>

    </>:
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} md={9} className={classes.uploadGrid}>
        <div className={classes.rotateContainer}>
          <IconButton title="Rotate Back" onClick={handleRotate('top')}><Rotate90DegreesCcwIcon /></IconButton>
          <IconButton title="Rotate Left" onClick={handleRotate('left')}><RotateLeftIcon /></IconButton>
          <IconButton title="Rotate Right" onClick={handleRotate('right')}><RotateRightIcon /></IconButton>
          <IconButton title="Rotate Front" onClick={handleRotate('bottom')}><Rotate90DegreesCcwIcon style={{transform: 'scaleX(-1)'}}/></IconButton>
        </div>
        <img src="https://cdn.sunsh1n3.com/Images/queu3d/QUEU3D_Beta_Logo.png" width="300" height="90" style={{position:'absolute', top: 90, left: 10}}/>
        <Viewer ref={viewerRef} file={file} handleAddFile={handleAddFile} onUploadComplete={handleUploadFile} fileType={fileType} color={color} previewLoaded={previewLoaded} onLoaded={setModelData} scale={scale} />
        {file && <div className={classes.uploadIconContainer} onClick={reset}><div className={classes.uploadIcon}><img src="/icons/upload.svg" alt="upload"/></div></div>}
      </Grid>
      <Grid item xs={12} md={3} className={classes.settings}>
        <Paper className={classes.paperSummary}>
          <div className={classes.paperSummary} style={{paddingTop:'2rem'}}>
          <Typography variant='body1'>Model</Typography>
          <Typography variant='body1'>Size: {modelData.modelSize?.width.toFixed(2)}mm x {modelData.modelSize?.height.toFixed(2)}mm x {modelData.modelSize?.depth.toFixed(2)}mm</Typography>
          <Typography variant='body1'>Material{sliceMap[getKeys().dataKey]?": ":"~ "} {getVolume()}g</Typography>
          </div>
          <div className={classes.paper}>
            <Typography variant='body1'>MATERIAL</Typography>
            <FormControl className={classes.formControl}>
              <Select
                labelId="demo-mutiple-name-label"
                id="demo-mutiple-name"
                value={material}
                onChange={handleChange}
                input={<Input />}
                MenuProps={MenuProps}
              >
                {materials.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.paperSummary}>
          <Accordion square={true} className={classes.accordian}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Grid container >
                <Grid item md={6}>
                  <Typography variant='body1'>COLOR</Typography>
                </Grid>
                <Grid item md={5} style={{textAlign:'right', paddingRight:'1rem'}}>
                  <Typography variant='body1'>{color}</Typography>
                </Grid>
                <Grid item md={1}>
                  <div style={{float:'right', height:'3rem', width:'3rem', borderRadius:'2rem', backgroundColor:getColor(color)}}></div>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
            {colorMap[material]?
                  <ColorPicker 
                    onChange={colorChange}
                    color={color}
                    colors={colorMap[material]}
                    />:null}
            </AccordionDetails>
          </Accordion>
          </div>
          <div style={{height:"4rem"}}></div>
          <div className={classes.paper}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item sm={6}>
                <Typography variant='body1'>Quality - .{getPrice().cost}&#162;<Typography variant='caption'>/g</Typography></Typography>
              </Grid>
              <Grid item sm={6} style={{textAlign:'right'}}>
                <Typography variant='body1'>{(marks.find(obj => obj.value === quality)).title}</Typography>
              </Grid>
              <Grid item sm={12}>&nbsp;</Grid>
              <Grid item sm={12}>
                <Slider
                  ValueLabelComponent={ValueLabelQualityComponent}
                  aria-label="custom thumb label"
                  value={quality}
                  marks={marks}
                  step={null}
                  className={classes.slider}
                  // valueLabelDisplay="on"
                  onChange={(e,v)=>{
                      setQuality(v)
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <div style={{height:"2rem"}}></div>
          <div className={classes.paper}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item sm={6}>
                <Typography variant='body1'>Scale</Typography>
              </Grid>
              <Grid item sm={6} style={{textAlign:'right'}}>
                <Typography variant='body1'>{scale}%</Typography>
              </Grid>
              <Grid item sm={12}>&nbsp;</Grid>
              <Grid item sm={8}>
                <Slider
                  ValueLabelComponent={ValueLabelComponent}
                  aria-label="custom thumb label"
                  value={scale}
                  min={0}
                  max={500}
                  disabled={progress>0}
                  onChange={(e,v)=>{
                      setScale(v)
                  }}
                />
              </Grid>
              <Grid item sm={3}>
                <FormControl >
                  <TextField
                    id="scale"
                    value={scale}
                    type="number"
                    min="0" 
                    max="500"
                    disabled={progress>0}
                    variant="outlined"
                    size="small"
                    onChange={(e,val)=>{
                      let tmp = Number(e.target.value);
                      setScale(tmp)
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end" className={classes.InputAdornment}>%</InputAdornment>,
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <div className={classes.paper}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item sm={6}>
                <Typography variant='body1'>INFILL</Typography>
              </Grid>
              <Grid item sm={6} style={{textAlign:'right'}}>
                <Typography variant='body1'>{infill}%</Typography>
              </Grid>
              <Grid item sm={12}>&nbsp;</Grid>
              <Grid item sm={8}>
                <Slider
                  ValueLabelComponent={ValueLabelComponent}
                  aria-label="custom thumb label"
                  value={infill}
                  disabled={progress>0}
                  onChange={(e,v)=>{
                      setInfill(v)
                  }}
                />
              </Grid>
              <Grid item sm={3}>
                <FormControl >
                  <TextField
                    id="infill"
                    value={infill}
                    type="number"
                    min="0" 
                    max="100"
                    disabled={progress>0}
                    variant="outlined"
                    size="small"
                    onChange={(e,val)=>{
                      let tmp = Number(e.target.value);
                      tmp = tmp>100?100:tmp;
                      setInfill(tmp)
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end" className={classes.InputAdornment}>%</InputAdornment>,
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <div className={classes.paper}>
            <Typography variant='body1'>QUANTITY</Typography>
            <div style={{display:'flex', alignItems:'center', }}>
              <IconButton
                disableRipple
                className="qty-btn remove"
                disabled={qty <= 1}
                onClick={e => {setQty(tmp=>tmp-1)}}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
              <Typography id="qty-txt">{qty}</Typography>
              <IconButton
                disableRipple
                className="qty-btn add"
                onClick={e => {setQty(tmp=>tmp+1)}}
              >
                <AddCircleIcon />
              </IconButton>
            </div>
          </div>
          {/* <Typography variant='body1'style={{flex:'1 0 80%'}}>Print as Vase<sup>*</sup></Typography>
          <Checkbox
            checked={isVase}
            color="primary"
            style={{color:'black',flex:'1'}}
            onChange={setVaseMode}
            inputProps={{ 'aria-label': 'Suports' }}
          />
          <Typography variant='caption' ><sup>*</sup>Hollow and no top layer</Typography> */}
          {preview &&
            <img src={preview} style={{float:'left', padding: '3rem', maxWidth:150, maxHeight:150, width:'auto', height:'auto'}} height="200" width="200" alt="preview" />
          }
          <div style={{ padding: '3rem 0'}}>
          <Typography variant='body2'>/EA</Typography>
          <Typography variant='body2'>${!sliceMap[getKeys().dataKey]?.filamentUsage?0:getPrice().itemPrice.toFixed(2)}</Typography>

          <br/>
          <br/>
          {progress > 0 ? 
            <>
              <Typography variant='body1'>CALCULATING</Typography>
              <CircularProgressWithLabel value={progress} />
            </>:
            <>
              <Typography variant='body1'>TOTAL</Typography>
              <Typography variant='body1' color="primary">${getPrice().subTotal.toFixed(2)}</Typography>
              </>
            }
          </div>
          <br/>
          <br/>
          <Button
            className={classes.button}
            // disabled={!(currentItem.qoh > 0)}
            // endIcon={<ShoppingCart size={16} />}
            fullWidth
            color="default"
            onClick={reset}
            variant="contained">
            Cancel
          </Button>
          <div style={{position:'relative'}}>
            {!sliceMap[getKeys().dataKey]?.filamentUsage ?
            <Button
            className={classes.button}
            disabled={!file || progress > 0}
            // endIcon={<ShoppingCart size={16} />}
            fullWidth
            color="primary"
            onClick={calculatePrice}
            variant="contained">
            Calculate Price &nbsp; {progress>0 ? <CircularProgress size="2rem" />:<LocalOfferIcon size="small"/>}
          </Button>
            :
          <Button
            className={classes.button}
            disabled={!file || !sliceMap[getKeys().dataKey]?.filamentUsage ||!modelData.modelVolume || modelData.oversized === true || uploading === true || getPrice().subTotal === 0}
            // endIcon={<ShoppingCart size={16} />}
            fullWidth
            color="primary"
            onClick={addToCart}
            variant="contained">
            Add to Cart &nbsp;<ShoppingCartIcon size="small"/>
          </Button>}
          {uploading && <CircularProgress size="3rem" className={classes.progress} />}
          </div>
        </Paper>
      </Grid>
    </Grid>
}
    </>
  );
};

function getRandomItem(arr) {
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
}

export default PodPage;
