import React from "react"
import { Link } from "react-router-dom"
import {styled} from '@material-ui/core';
import {Typography} from '@material-ui/core';
import ProductStyles from './styles';
import TextField from '@material-ui/core/TextField';
import { Button } from "@material-ui/core";
// Material UI Icons
import SearchIcon from '@material-ui/icons/Search';
import {ReactComponent as StandardIcon} from '../../shop/standard.svg'
import {ReactComponent as PremiumIcon} from '../../shop/premium.svg'
import {ReactComponent as CreativeIcon} from '../../shop/creative.svg'
import { Helmet } from "react-helmet";
import metadata from "../../shop/meta";

const Premiumlist = styled('div')({
  fontSize:"6rem",
  textAlign:"left",
  width:"100%",
  // marginTop:'-4rem',
  color:'#111'
},{name:"Premium-member-benefits"});


const TextContainer = styled('div')(
  {
    border: '2px solid #A3AAAB33',
    borderRadius: 10,
    padding:'8rem',
    margin: '0 10px 40px 10px',
  },
  {name: 'Header'}
);

const TitleText = styled(Typography)(
  {
    textAlign: 'left',
    font: 'normal normal 600 20px/30px Poppins',
    letterSpacing: 0,
    color: '#111111'
  },
  {name: 'Header'}
);

const SectionSubText = styled(Typography)(
  {
    textAlign: 'left',
    font: 'normal normal normal 16px/28px Poppins',
    letterSpacing: 0,
    color: '#111111'
  },
  {name: 'Header'}
);

const Spacer = styled('div')(
  {
    height:'8rem'
  },
  {name: 'Spacer'}
);
const TopCopy = ({category})=>{
  const pgStyles = ProductStyles();
    switch(category) {
      case 'ABS': 
      return <><Typography variant="h1" className={pgStyles.header} gutterBottom={true}>ABS 3D Printing Filament</Typography>
              <Typography className={pgStyles.subText} paragraph={true} variant="h2">At PrintBed, our Acrylonitrile Butadiene Styrene (ABS) filament is a durable, heat-resistant thermoplastic, known for its strength and flexibility during the 3D printing process. We are confident that our ABS spools are top of the line in terms of color, quality, and perfectly rolled spools and will help turn your dreams and ideas into reality.</Typography></>
      
      case 'PETG': 
      return <><Typography variant="h1" className={pgStyles.header} gutterBottom={true}>PETG Filament</Typography>
              <Typography className={pgStyles.subText} paragraph={true} variant="h2">Renowned for its excellent balance between flexibility, strength, and ease of printing, PETG is a popular filament that has a high resistance to impact. At PrintBed, PETG rolls are available in black, white, and <Link to="/shop/PETG/Clear-1.75mm-1kg/">clear filaments</Link>. Each spool is wound to perfection, ensuring smooth and consistent printing that’s tangle-free along with precise color accuracy.</Typography></>
      case 'AcryBlend': 
       return <><Typography variant="h1" className={pgStyles.header} gutterBottom={true}>AcryBlend Filament</Typography>
               <Typography className={pgStyles.subText} paragraph={true} variant="h2">Looking for a high-shine, translucent finish on your products? Our AcryBlend 3D printer filaments are exactly what you need. Choose from a range of precision-crafted filaments that guarantee color consistency across your batches even when they’re printed years apart. Our specially-built tangle-free spools ensure that the filaments don’t jam your printer mid-run.</Typography></> 
      case 'FORGE': 
      return <><Typography variant="h1" className={pgStyles.header} gutterBottom={true}>Forge PLA+ Filament</Typography>
              <Typography className={pgStyles.subText} paragraph={true} variant="h2">Forge PLA+ is an enhanced version of our <Link to="/shop/PLA/">standard PLA filament</Link> with higher strength and durability, better layer adhesion, and more flexibility. It’s a more heat-resistant PLA filament as it melts at higher temperatures. PrintBed uses a proprietary system that ensures perfectly wound, tangle-free filament for each PLA+ spool. We also use color-sensing technology for perfect color accuracy across all batches.</Typography></>
      
      case 'PLA':
            return <><Typography variant="h1" className={pgStyles.header} gutterBottom={true}>Standard Color PLA 3D Printer Filament</Typography>
            <Typography className={pgStyles.subText} paragraph={true} variant="h2" >Our standard filament colors are wound perfectly and never get tangled, ensuring smooth and pristine prints. These core colors are consistent from one batch to the next so you can always maintain precision and accuracy in your printing. Our standard colors are available in two diameters: 1.75 mm PLA 3D filaments which are most commonly used for desktop 3D printers, and thicker 2.85 mm spools for printers requiring larger filaments.</Typography>
        </> 
      case 'PLA Ltd':
            return <><Typography variant="h1" className={pgStyles.header} gutterBottom={true}>Cool Limited Edition</Typography>
            <Typography variant="h1" className={pgStyles.header} gutterBottom={true}>3D Printer Filament</Typography>
            <Typography className={pgStyles.subText} paragraph={true} variant="h2" >Our Limited Edition colors are unique and not found in our standard collection. These spools are wound to perfection and provide tangle-free filament for precise printing. A high level of quality control also ensures reliable color consistency across batches. Although these cool PLA filaments are available for a limited time, they may be reintroduced depending on demand.</Typography>
        </> 
        default:
            return <><Typography variant="h1" className={pgStyles.header} gutterBottom={true}>Buy 3D Printing Rolls</Typography>
            <Typography className={pgStyles.subText} paragraph={true} variant="h2" >Choose from a wide range of 3D printing spools with reliable materials that are consistently high in quality and performance. You can buy 3D printer filaments that are perfectly wound to ensure the material is neat and never gets tangled during printing. The colors of our filaments are unique and consistent, ensuring you always get the right shade between batches.</Typography>
        </>  
           
    }
}
const BottomCopy = ({category}) => {
  const pgStyles = ProductStyles();

    switch(category) {
      case 'FORGE':
        return<>
          <TextContainer>
            <StandardIcon />
            <TitleText variant="h2" gutterBottom={true}>The Benefits of PLA+</TitleText>
            <SectionSubText paragraph={true} variant="body1" >Since PLA+ is more heat resistant than standard PLA, it tends to exhibit less warping and shrinking during printing, making it well-suited to large or intricate 3D prints. PLA+ filament tends to melt at a temperature of between 401°F and 446°F, which helps maintain consistent extrusion in your printer which is integral for reliable prints and accurate dimensions.</SectionSubText>
            <SectionSubText paragraph={true} variant="body1" >The possibilities are endless when it comes to designing 3D prints with this material. From customizable gear knobs for bicycles to robust replacement components for household appliances, PLA+ is a popular choice.</SectionSubText>
          </TextContainer>
          <TextContainer>
            <PremiumIcon/>
            <TitleText variant="h2"  gutterBottom={true}>Activate Creativity with PrintBed Membership</TitleText>
            <SectionSubText paragraph={true} variant="body1">Premium subscribers receive a new and exclusive color delivered to their doorstep monthly. For just $25 per month, you’ll receive a unique color from our Premium colors like Cosmic Purple, <Link to="/shop/PLA%20Ltd/Cookies%20N'%20Cream-1.75mm-1kg/" >Cookies N’ Cream</Link>, or Black Cherry each month. <Link to="/shop/PLA Ltd/Mystery-1.75mm-1kg/">Mystery Spools</Link> from our vending machine are also available for Premium members and these colors allow you to experiment with our surprise R&D colors. Plus, members receive 10% off other filaments like PLA+, free ground shipping in the USA, and exclusive voting rights for new colors.</SectionSubText>
            <Button href="/premium/" style={{fontSize:'6rem'}} size="large" variant="contained" color="primary">Subscribe to Premium</Button>
          </TextContainer>
          <TextContainer>
            <CreativeIcon />
            <TitleText variant="h2"  gutterBottom={true}>Spark Your Imagination with Heat-Resistant PLA Filament</TitleText>
            <SectionSubText  paragraph={true} variant="body1" >Our team is ready to help by phone, email, and social media so you can contact us any time and we’ll get back to you as soon as possible. Overnight shipping in the contiguous US ensures your innovative ideas don’t have to wait! <a href="https://web.facebook.com/printbed/?_rdc=1&_rdr" target="_blank">Tag us</a> when you display your prints on social media and follow us to keep up to date with our latest products.</SectionSubText>
          </TextContainer>
        </>
        break;
      case 'PETG':
          return<>
            <TextContainer>
              <StandardIcon />
              <TitleText variant="h2" gutterBottom={true}>Essential Aspects of PETG Spools</TitleText>
              <SectionSubText paragraph={true} variant="body1" >Our PETG rolls are an outstanding choice for creating robust prototypes that look and function almost exactly like the final product. This makes PETG ideal for projects like mechanical parts, outdoor equipment, and medical devices.</SectionSubText>
              <SectionSubText paragraph={true} variant="body1" >PETG filament has a fairly high heat resistance, usually melting between 401°F and 446°F. It also has a higher resistance to UV radiation than other filaments, making it suitable for outdoor prints where parts may be exposed to sunlight. You can purchase our PETG spools in clear, rather than color, which makes them perfect for products that need to be transparent and transmit light such as lamps and light fixtures.</SectionSubText>
            </TextContainer>
            <TextContainer>
              <PremiumIcon/>
              <TitleText variant="h2"  gutterBottom={true}>PrintBed Premium Benefits</TitleText>
              <SectionSubText paragraph={true} variant="body1">Membership at PrintBed allows you to get early access to exclusive Premium colors. For just $25 per month, a Premium spool gets shipped to you every month so you can experiment with new colors and unleash your creativity. Premium membership also unlocks extra benefits including 10% off other spools like our PETG filaments, free US ground shipping, vending machine mystery spools, and the ability to vote for new Premium exclusive colors.</SectionSubText>
              <Button href="/premium/" style={{fontSize:'6rem'}} size="large" variant="contained" color="primary">Subscribe for Unique PLA Filament</Button>
            </TextContainer>
            <TextContainer>
              <CreativeIcon />
              <TitleText variant="h2"  gutterBottom={true}>Get High-Quality PETG Rolls</TitleText>
              <SectionSubText  paragraph={true} variant="body1" >PrintBed prioritizes high-quality <Link to="/">3D filaments</Link> and an unrivaled dedication to customer service, ensuring the best solutions are constantly provided. We’re available via phone, email, and social media platforms like <a href="https://www.instagram.com/print_bed/" target="_blank">Instagram</a> and <a href="https://www.facebook.com/printbed/" target="_blank">Facebook</a> so you’re always kept up to date. Products are delivered overnight so your original thoughts don’t have to wait. Connect with us on social media and be sure to tag us when you show off your 3D prints.</SectionSubText>
            </TextContainer>
          </>
          break;
          case 'AcryBlend':
            return<>
              <TextContainer>
                <StandardIcon />
                <TitleText variant="h2" gutterBottom={true}>Achieve a Superior Finish With AcryBlend Filaments From PrintBed</TitleText>
                <SectionSubText paragraph={true} variant="body1" >Polymethyl methacrylate (PMMA) or acrylic filaments give your prints an almost transparent look and are often a good replacement for glass. The finished products tend to be very durable with high-impact resistance in addition to resistance against UV and other extreme weather conditions. PMMA also has a lower melting point and is half as heavy compared to glass, making it easier to print. Its print temperature varies between 230 and 250ºC.</SectionSubText>
                <SectionSubText paragraph={true} variant="body1" >At PrintBed, we go to great lengths to ensure our feedstock is manufactured in an eco-friendly way. Even our spools are made from recyclable chipboard and packaged in cardboard boxes. Lower your carbon footprint with high-quality 3D filament from PrintBed.</SectionSubText>
              </TextContainer>
              <TextContainer>
                <PremiumIcon/>
                <TitleText variant="h2"  gutterBottom={true}>PGet More Out of PrintBed With Premium</TitleText>
                <SectionSubText paragraph={true} variant="body1">A PrintBed Premium subscription gives you 10% off on all your purchases and free on-ground shipping, for just $25/month. It also gives you access to a range of new colors beyond our Standard collection as well as a vote on new monthly releases. </SectionSubText>
                <Button href="/premium/" style={{fontSize:'6rem'}} size="large" variant="contained" color="primary">Get Started With Premium</Button>
              </TextContainer>
              <TextContainer>
                <CreativeIcon />
                <TitleText variant="h2"  gutterBottom={true}>Unrivaled Customer Support</TitleText>
                <SectionSubText  paragraph={true} variant="body1" >At PrintBed, we strive to deliver complete satisfaction on every purchase, and part of this is providing exceptional customer support. Our trained staff is on standby to help you with any queries you may have; you can reach out to them via phone, <a href="mailto:support@printbed.com">email</a>, and social media. Order your acrylic 3D filaments today and get overnight shipping across mainland US for just $10. Like how your prints turned out? Tag us on social media and let us know!</SectionSubText>
              </TextContainer>
            </>
            break;
          case 'ABS':
            return<>
              <TextContainer>
                <StandardIcon />
                <TitleText variant="h2" gutterBottom={true}>Made With The Best Materials Available</TitleText>
                <SectionSubText paragraph={true} variant="body1" >Our ABS spools are made from a blend of top-tier polymers, creating a thermoplastic that offers high durability, impact resistance, and flexibility. The manufacturing process not only ensures quality, but strength, smooth surface finish, and perfect color, making it a preferred material for both industrial applications and hobbyist projects. Our ABS 3D printer filament is also a heat-resistant material, making it suitable for creating functional parts that must withstand various stresses.</SectionSubText>
              </TextContainer>
              <TextContainer>
                <PremiumIcon/>
                <TitleText variant="h2"  gutterBottom={true}>Try Premium Today!</TitleText>
                <SectionSubText paragraph={true} variant="body1">For customers looking to get the most out of PrintBed, we offer a Premium subscription for just $25 a month! With Premium, our customers have access to exclusive color releases each month, 10% off all other filaments, free ground shipping, and more! This is our way of saying thank you to our customers for trusting us with constructing their prototypes.</SectionSubText>
                <Button href="/premium/" style={{fontSize:'6rem'}} size="large" variant="contained" color="primary">Join Premium!</Button>
              </TextContainer>
              <TextContainer>
                <CreativeIcon />
                <TitleText variant="h2"  gutterBottom={true}>Why Shop With PrintBed?</TitleText>
                <SectionSubText  paragraph={true} variant="body1" >Here at PrintBed, we consider our customer’s satisfaction to be paramount in our business model. This is why we are determined to provide the best ABS <Link to="/">3D printer filament</Link> on the market with flawless colors and perfectly wound spools every time. No matter what you need our products for, we know they’ll get the job done and you’re always welcome to come back for more without any worries about color variation. <Link to="/shop/">Buy 3D printer filament</Link> from PrintBed today!</SectionSubText>
              </TextContainer>
            </>
            break;
      case 'PLA':
        return <>
        <TextContainer>
          <StandardIcon />
          <TitleText variant="h2" gutterBottom={true}>PrintBed PLA Printing Filament</TitleText>
          <SectionSubText paragraph={true} variant="body1" >Polylactic acid, or PLA, is a filament that is versatile and easy to use. PLA filament starts to soften at about 140°F and has a low melting temperature of about 320-374°F. This enables it to be printed on most desktop 3D printers without a heated printing bed. PLA is unlikely to warp which means your prints will form well and stay durable.</SectionSubText>
          <SectionSubText paragraph={true} variant="body1" >PLA is available in various colors, enabling you to be creative in your designs. Both our 1.75 mm and 2.85 mm PLA 3D filaments have a decent amount of tensile strength, meaning it can withstand a fair amount of tension before breaking. This makes PLA ideal for printing design concepts like prototypes. PLA 3D printer filament is also well-suited to visually appealing prints like decorative items, sculptures, and educational models.</SectionSubText>
        </TextContainer>
        <TextContainer>
          <PremiumIcon/>
          <TitleText variant="h2"  gutterBottom={true}>The Perks of Premium Subscription</TitleText>
          <SectionSubText paragraph={true} variant="body1">For just $25/month, a Premium color spool is delivered to your door each month. Premium colors have unique finishes and there are also specialty colors like glow-in-the-dark 1.75 mm PLA 3D filament. If you start a <Link to="/premium/">Premium filament subscription</Link>, you’ll also get 10% off our other <Link to="/">3D filaments</Link>, free ground shipping, access to spools from the mystery vending machine, and you can vote for new colors.</SectionSubText>
        </TextContainer>
        <TextContainer>
          <CreativeIcon />
          <TitleText variant="h2"  gutterBottom={true}>Empower Your Creativity with PrintBed</TitleText>
          <SectionSubText  paragraph={true} variant="body1" >At PrintBed, we’re dedicated to addressing any questions to ensure you have an optimal 3D printing experience with our products. You can reach us by phone, email, or on social media and we’ll get back to you as soon as possible. We make sure your ideas don’t have to wait so we package the 1.75 mm PLA 3D filament of your choice and ship it to your door overnight. Tag us on <a href="https://www.instagram.com/print_bed/" target="_blank">Instagram</a> or <a href="https://www.twitch.tv/printbed" target="_blank">Twitch</a> when you display your innovative designs!</SectionSubText>
        </TextContainer></>
      case 'PLA Ltd':
        return <>
        <TextContainer>
          <StandardIcon />
          <TitleText variant="h2" gutterBottom={true}>High-Quality PLA Filament</TitleText>
          <SectionSubText paragraph={true} variant="body1" >Our Limited Edition spools contain a type of filament made from polylactic acid (PLA). PLA is a natural polyester obtained from renewable resources such as sugarcane and corn starch, making it environmentally friendly. PLA is also one of the easiest materials to use. These cool 3D printer filaments have a low melting temperature, making them easy to print with and less likely to warp than other filaments. This material is also safe as it doesn’t emit any harmful fumes.</SectionSubText>
          <SectionSubText paragraph={true} variant="body1" >Our PLA <Link to="/">3D filaments</Link> are relatively strong, making them suitable for various applications. You can create items like prototypes, decorative pieces, design objects, and more.</SectionSubText>
        </TextContainer>
        <TextContainer>
          <PremiumIcon/>
          <TitleText variant="h2"  gutterBottom={true}>Exclusive Premium Membership</TitleText>
          <SectionSubText paragraph={true} variant="body1">A Premium subscription at PrintBed gives you access to exclusive colors, allowing you to create exciting 3D prints. As a Premium member, you’ll receive a new color delivered to your doorstep every month, giving you first access to recently released and inspiring cool 3D printer filaments. Members also unlock additional perks including 10% off filaments, free ground shipping, and the ability to vote for new colors. Sign up for our <Link to="/premium/">filament subscription box</Link> to experience the full benefits of PrintBed.</SectionSubText>
        </TextContainer>
        <TextContainer>
          <CreativeIcon />
          <TitleText variant="h2"  gutterBottom={true}>Be the First to Receive Cool PLA Filaments</TitleText>
          <SectionSubText  paragraph={true} variant="body1" >PrintBed is dedicated to providing exceptional customer service. You can contact us via phone, email, and social media on platforms like <a href="https://www.facebook.com/printbed/" target="_blank">Facebook</a> and <a href="https://twitter.com/printbed" target="_blank">X (Formerly Twitter)</a> if you have any questions or concerns. We often announce Limited Edition colors on social media so follow us and tag us when you show off your creative 3D prints!</SectionSubText>
        </TextContainer></>
        default:
            return <>
            <TextContainer>
              <StandardIcon />
              <TitleText variant="h2" gutterBottom={true}>PrintBed Standard PLA Spools</TitleText>
              <SectionSubText paragraph={true} variant="body1" >Our standard PLA spools are made from greenhouse gasses, making them the most environmentally friendly <Link to="http://www.printbed.com">3D printer filament</Link> of all the materials. Ethically produced and sourced here in the USA, our 3D filament for sale is easy to print with and doesn’t require a heated bed. These PLA 3D printing rolls are available in exciting, consistent colors so you always get the same shade for your projects.</SectionSubText>
            </TextContainer>
            <TextContainer>
              <PremiumIcon/>
              <TitleText variant="h2"  gutterBottom={true}>PrintBed Premium Membership</TitleText>
              <SectionSubText paragraph={true} variant="body1">Our Premium membership is a monthly subscription that offers various benefits to 3D printing enthusiasts. An exciting new color that inspires creativity is released monthly exclusively for Premium members. For just $25 per month, the new colors are shipped directly to your door and you also unlock other PrintBed Premium benefits:</SectionSubText>
              <Premiumlist>
                <ul>
                  <li>10% off other 3D printing rolls</li>
                  <li>Free ground shipping</li>
                  <li>Vote for new colors</li>
                  <li>Vending machine for mystery spools</li>
                </ul>
              </Premiumlist>
              <SectionSubText  paragraph={true} variant="body1" >Premium members can also buy 3D printer filament Premium colors from previous months. Unlock the advantages of PrintBed Premium and start a <Link to="/premium/">filament subscription</Link> today!</SectionSubText>
            </TextContainer>
            <TextContainer>
              <CreativeIcon />
              <TitleText variant="h2"  gutterBottom={true}>Unleash Your Creativity with PrintBed</TitleText>
              <SectionSubText  paragraph={true} variant="body1" >At PrintBed, we are committed to excellent customer service. Products are shipped overnight so your original ideas and inventiveness don’t need to wait. If you have any questions or concerns, you can contact our team by phone, email, or on social media platforms and we’ll get back to you as quickly as possible. Follow us on <a href="https://www.instagram.com/print_bed/" target="_blank">Instagram</a> and <a href="https://www.tiktok.com/@printbed" target="_blank">TikTok</a> and tag us when you show off your prototypes!</SectionSubText>
            </TextContainer></>
    }
}

const Headers = ({category}) => {
  if(metadata[category]) {
    return <Helmet>
    <title>{metadata[category].title}</title>
    <meta name="description" content={metadata[category].desc} />
  </Helmet>
  } else {
    switch(category) {
    case 'PLA':
        return <Helmet>
        <title>1.75 mm PLA 3d filament | Tangle-Free Spools | PrintBed</title>
        <meta name="description" content="Get the ideal 1.75 mm PLA 3D filament to bring your designs to reality with PrintBed. Print with quality PLA 3D printer filament that never tangles. Order now." />
      </Helmet>
    case 'PLA Ltd':
      return <Helmet>
      <title>Cool 3D Printer Filament | Limited Edition Colors | PrintBed</title>
      <meta name="description" content="Get cool 3D printer filament from PrintBed and create unique 3D applications. Our Limited Edition spools are tangle-free and consistent across all colors." />
    </Helmet>
      default: 
        return <Helmet>
        <title>3D Printing Rolls | Quality Tangle-Free Spools | PrintBed</title>
        <meta name="description" content="Get tangle-free 3D printing rolls that help prevent issues during printing. Buy consistent 3D printer filament from PrintBed and bring your design to reality." />
      </Helmet>
    }
  }
}


export {TopCopy,BottomCopy, Headers};