/** @format */

import React from 'react';
import {Link} from 'react-router-dom';
// Material UI Components
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import {styled} from '@material-ui/core/styles';
// Custom imports

//#region Styled Components

const BadgeTxt = styled(Typography)(
  {
    alignItems: 'center',
    borderRadius: '0px',
    display: 'flex',
    flexWrap: 'nowrap',
    fontSize: '1.5rem',
    fontWeight: '600',
    height: '2.75rem',
    justifyContent: 'center',
    marginBottom: '0.5rem',
    padding: '0 1rem',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    width: 'min-content',
    '&.limited': {
      backgroundColor: 'lightpink',
      color: '#000',
    },
    '&.new': {
      backgroundColor: 'orangered',
      color: '#fff',
    },
    '&.outOfStock': {
      backgroundColor: '#000',
      color: '#fff',
    },
    '&.sale': {
      backgroundColor: 'royalblue',
      color: '#fff',
    },
    '&.premium': {
      backgroundColor: 'purple',
      color: '#fff',
    },
  },
  {name: 'badge-txt'}
);

const BadgeWrapper = styled('div')(
  {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    left: '16px',
    position: 'absolute',
    top: '16px',
    width: 'min-content',
  },
  {name: 'badge-wrap'}
);

const AddToCartButton = styled(Button)(
  {
    color:'black',
    border:'2px solid #EEEEEE',
    background: 'white',
    borderRadius: '1rem',
    minWidth: 0,
    float:'right',
    // cursor:'pointer'
},
  {name: 'add-to-cart-button'}
);

const CardAction = styled(CardActionArea)(
  {
    borderRadius: '16px',
    height: '100%',
  },
  {name: 'card-action'}
);

const CardImg = styled(CardMedia)(
  {
    height: 207,
    backgroundSize: 'contain',
    padding: '2rem',
    width: '100%',
    // maxHeight: 300,
  },
  {name: 'card-img'}
);

const CardTxt = styled(Typography)(
  {
    color: '#000',
    fontSize: '4rem',
    fontWeight: '500',
    '&.product-name': {
      '&.mbl': {
        fontSize: '6rem',
      },
    },
    '&.product-price': {
      fontSize: '3rem',
      '& .sup-txt': {
        fontSize: '2rem',
      },
      '&.mbl': {
        fontSize: '6rem',
        '& .sup-txt': {
          fontSize: '3rem',
        },
      },
    },
  },
  {name: 'card-txt'}
);

const ContentWrapper = styled(CardContent)(
  {
    '& .product-name': {
      color: '#000',
      fontWeight: '500',
      fontSize: '2rem',
      // eslint-disable-next-line no-useless-computed-key
      ['@media (max-width: 500px)']: {
        fontSize: '8rem !important',
      },
      '& .product-price': {
        fontSize: '2rem',
        // eslint-disable-next-line no-useless-computed-key
        ['@media (max-width: 500px)']: {
          fontSize: '8rem !important',
        },
        position: 'relative',
        display: 'inline-block',
        color: '#bbb',
      },
    },
  },
  {name: 'content-wrap'}
);

const StyledCard = styled(Card)(
  {
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '270px',
    height:'auto',
    maxHeight:400,
    '&.mbl': {
      width: '25%',
    },
  },
  {name: 'styled-card'}
);

//#endregion

export default function ItemCard(props) {
  const {name, price, isMobile, imgSrc, qoh, tags, data, id, cartUtils} = props;

  const defaultTags = {
    color: 'Black',
    material: 'PLA',
    weight: '1kg',
    diameter: '1.75mm',
    colorName: 'Black',
  };

  function getItemLink() {
    let returnLink = '';
    if (tags.weight !== undefined && tags.weight === '200g') {
      returnLink += '/shop/minis/';
    } else if (
      tags.weight !== undefined &&
      tags.weight === '1kg' &&
      tags.color === 'BulkBox'
    ) {
      returnLink += '/shop/bulk/';
    } else {
      returnLink += `/shop/${getTagVal('material')}/${getTagVal(
        'color'
      )}-${getTagVal('diameter')}-${getTagVal('weight')}/`;
    }
    return returnLink;
  }

  function getTagVal(tagName) {
    if (
      Object.keys(tags).includes(tagName) ||
      Object.keys(defaultTags).includes(tagName)
    ) {
      return tags[tagName] ?? defaultTags[tagName];
    } else {
      // Error: tagName isn't recognized or doesn't exist
    }
  }

  const badgeClassTxt = {
    outOfStock: 'out of stock',
    limited: 'limited',
    new: 'new',
    sale: 'sale',
    premium: 'Premium Exclusive',
  };

  function getBadgeClasses() {
    let classes = [];
    if (qoh <= 0) {
      classes.push('outOfStock');
    }
    if (tags.material !== undefined && tags.material === 'PLA Ltd') {
      classes.push('limited');
    }
    // TODO: tests for other badges
    if (tags.sale !== undefined && tags.sale === 'true') {
      classes.push('sale');
    }
    if (tags.material !== undefined && tags.material === 'PLA Sub') {
      classes.push('premium');
    }
    if (tags.new !== undefined && tags.new === 'true') {
      classes.push('new');
    }
    return classes;
  }

  function getBadges() {
    let badgeClasses = getBadgeClasses();
    return badgeClasses.length > 0 ? (
      <BadgeWrapper>
        {badgeClasses.map(className => {
          return (
            <BadgeTxt className={className} key={`${className}-badge`}>
              {badgeClassTxt[className]}
            </BadgeTxt>
          );
        })}
      </BadgeWrapper>
    ) : null;
  }

  const addToCart = () => {
    let tmp = {
      id: id,
      quantity: 1,
      options: tags,
    };
    cartUtils.addItemToCart(tmp);
  }

  const showButton = () => {
    let mat = getTagVal('material').toLowerCase();
    let weight = getTagVal('weight').toLowerCase();
    return !(isMobile || mat === 'bulk' || weight === '200g' || mat === 'kit')
  }

  return (
    <StyledCard className={isMobile ? 'mbl' : ''} elevation={0}>
      <CardAction>
        <Link to={getItemLink()}>
          {getBadges()}
          <CardImg
            // component="img"
            alt={name}
            width="270"
            height="207"
            image={
              typeof imgSrc === typeof []
                ? imgSrc[0] +
                  `?h=${Math.round(270 * window.devicePixelRatio)}px`
                : imgSrc + `?h=${Math.round(270 * window.devicePixelRatio)}px`
            }
            title={name}
            onError={e => (e.target.src = '/images/PrintBed.svg')}
          />
        </Link>
      </CardAction>

      <ContentWrapper>
        {showButton() && <AddToCartButton title="Add to Cart" disabled={qoh<=0}onClick={addToCart} size="small"><AddIcon fontSize="small" /></AddToCartButton>}
        <Link to={getItemLink()}>
          <CardTxt
            variant="subtitle1"
            className={`product-name ${isMobile ? 'mbl' : ''}`}>
            {data && data.temp ? `${tags.colorName} ${tags.material}` : name}
          </CardTxt>
        </Link>
        <CardTxt
          variant="subtitle1"
          className={`product-price ${isMobile ? 'mbl' : ''}`}>
          ${Math.floor(price / 100)}
          <sup className="sup-txt">{getDecimal(price)}</sup>
        </CardTxt>
      </ContentWrapper>
    </StyledCard>
  );
}

const getDecimal = n => {
  let d = ((n / 100) % 1) * 100;
  if (d < 10) {
    return d + '0';
  } else {
    return d;
  }
};
